import React from "react"
import { css } from "@emotion/react"

const AboutUs = () => {

  return (
    <div
      css={AboutUsSectionStyles}
      style={{
        width: "100%",
        height:"100%"
      }}
    >
      <h1>about us</h1>
      <div className="about-ctn">
        <svg id="heart-icon" viewBox="0 0 89 79">
          <use href="/icons-sprite-map.svg#heart-icon" />
        </svg>
        <h3>our mission</h3>
        <p>
          We relieve decision makers from doubt and anxiety by providing
          flexible, meaningful and highly accurate data management.
        </p>
      </div>
      <div className="about-ctn">
        <svg id="bulb-icon" viewBox="0 0 58 92">
          <use href="/icons-sprite-map.svg#bulb-icon" />
        </svg>
        <h3>our story</h3>
        <p>
          Nadia and Johnny Petiot realized that most of the data management
          studies could be improved by updating tools and alleviating the
          burden on health leaders' shoulders.
        </p>
        <p>
          They realized they could thereby speed up health progress and reduce
          the anxiety entailed in the process.
        </p>
        <p>Nadia and Johnny therefore founded Focus DataScience in 2018.</p>
      </div>
      <div className="about-ctn">
        <svg id="rings-icon" viewBox="0 0 89 84">
          <use href="/icons-sprite-map.svg#rings-icon" />
        </svg>
        <h3>our values</h3>
        <p>Seamless, Precise, Passionate</p>
      </div>
    </div>
  )
}

export default AboutUs

const AboutUsSectionStyles = css`
  #section-about-us {
    padding-top: 2rem;
    padding-bottom: 7rem;
    margin-bottom: -1px;
    background-color: #08012d;
  }

  h1 {
    color: #f5f5f5;
    text-transform: uppercase;
    font-size: 3.15842535rem;
    font-weight: 500;
    letter-spacing: 1.44rem;
    text-align: center;
    margin-bottom: 9.6rem;
  }

  #heart-icon {
    width: 4.272rem;
    height: 3.792rem;
  }

  #bulb-icon {
    width: 2.784rem;
    height: 4.417rem;
  }

  #rings-icon {
    width: 4.272rem;
    height: 4.03rem;
  }

  #linkedin-icon {
    width: 2.4rem;
    fill: #f5f5f5;
  }

  .about-ctn {
    max-width: 25rem;
    margin: auto;
    margin-bottom: 8rem;
  }

  .about-ctn svg {
    margin: auto;
    display: block;
  }

  .about-ctn h3 {
    font-weight: 500;
    color: #f5f5f5;
    text-transform: uppercase;
    font-size: 1.334rem;
    text-align: center;
  }

  .about-ctn p {
    color: #f5f5f5;
    text-align: center;
  }


  @media (max-width: 1023px) {
    #section-about-us {
      padding-bottom: 2rem;
    }

    #section-about-us h1 {
      margin-bottom: 7rem;
    }

    .about-ctn {
      margin-bottom: 6rem;
    }

    #founder-ctn {
      margin-top: 9rem;
      flex-direction: column;
      align-items: center;
    }

    #founder-ctn h2 {
      text-align: center;
      margin-bottom: 2rem;
    }

    #founder-ctn > div:first-of-type {
      margin-bottom: 1rem;
    }
  }

  @media (max-width: 479px) {
    #section-about-us h1 {
      letter-spacing: 0.8rem;
    }

    #founder-ctn ul {
      margin-bottom: 2rem;
    }

    #founder-ctn > div:last-of-type > a {
      position: static;
      width: fit-content;
      margin: auto;
    }
  }
`
