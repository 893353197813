import Img from "gatsby-image"
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { css } from "@emotion/react"

export const Founder = () => {
  const data = useStaticQuery(graphql`
    {
      aboutImage: file(relativePath: { eq: "nadia-petiot.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <div css={FounderSectionStyles}
      style={{
        width: "100%",
        height: "100%"
      }}
    >
      <div id="founder-ctn">
        <div className="founder-gatsby-image">
          <Img
            fluid={data.aboutImage.childImageSharp.fluid}
            alt="Nadia Petiot"
          />
        </div>
        <div>
          <h2>about the founder</h2>
          <p>Nadia is a passionate data management expert.</p>
          <p>
            With more than 16 years of experience in clinical research and
            real-world outcomes, 6 years as senior clinical data manager and
            10 years as head of data management &amp; IT managing, Nadia is a
            fully reconized expert.
          </p>
          <p>
            Officialy qualified quality auditor since 2018, she started Focus
            DataScience with one mission: easing health progress.
          </p>
          <p>Some of her expertise fields include:</p>
          <ul>
            <li>- Epidemiology</li>
            <li>- Observational research</li>
            <li>- Patient, product and disease registries</li>
            <li>- Post approval safety studies</li>
            <li>- Patient reported outcomes</li>
            <li>- Pragmatic phase iv trials</li>
            <li>- Data management project management</li>
            <li>- Norms and regulations compliancy</li>
          </ul>
          <a href="https://www.linkedin.com/in/nadia-petiot-445ba515a/?originalSubdomain=fr">
            <svg id="linkedin-icon" viewBox="0 0 382 382">
              <use href="/icons-sprite-map.svg#linkedin-icon" />
            </svg>
          </a>
        </div>
      </div>
    </div>
  )
}

const FounderSectionStyles = css`

  #founder-ctn {
    max-width: 56.65rem;
    margin: 14rem auto auto auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  #founder-ctn > div:first-of-type {
    max-width: 21.5rem;
  }

  #founder-ctn img {
    display: block;
    border-radius: 0.4rem;
    width: 100%;
  }

  .founder-gatsby-image {
    max-width: 56.65rem;
    margin: auto;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }

  #founder-ctn h2 {
    font-weight: 500;
    text-align: left;
  }
  
  #linkedin-icon {
    width: 2.4rem;
    fill: #f5f5f5;
  }

  #founder-ctn p {
    color: #f5f5f5;
    font-weight: 300;
  }

  #founder-ctn ul {
    color: #f5f5f5;
    list-style-type: none;
    font-weight: 300;
    margin-bottom: 0;
    padding: 0;
  }

  #founder-ctn > div:last-of-type {
    max-width: 29.52rem;
    position: relative;
  }

  #founder-ctn > div:last-of-type > a {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  @media (max-height:900px){
    #founder-ctn{
      margin-top:7rem!important;
    }
  }
  @media screen and (max-width:900px){
    #founder-ctn{
      display:block !important;
      div{
        margin:auto;
        width:95%;
        text-align:center;
        h2{
          text-align:center!important;

        }
        ul{
          text-align:left;
        }
      }

    }
  }
  `
