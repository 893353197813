import BackgroundImage from "gatsby-background-image"
import React from "react"
import { css } from "@emotion/react"

export default function HeaderWithQuote({ quote, author, img, backgroundColor }) {

  return (
    <BackgroundImage
      css={HeaderWithQuoteStyle}
      style={{
        zIndex: "1",
        width: "100%",
        height: "100%"
      }}
      Tag="section"
      fluid={img}
      backgroundColor={backgroundColor}
    >
      <header
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column"
        }}>
        <blockquote>
          <p>
            <em dangerouslySetInnerHTML={{ __html: quote }} />
          </p>

          <p dangerouslySetInnerHTML={{ __html: author }} />
        </blockquote>
      </header>
    </BackgroundImage>
  )
}

const HeaderWithQuoteStyle = css`
  header {
    background-size: cover;
    background-position: center;
  }

  blockquote {
    text-align: center;
    margin: auto;
  }

  blockquote > p {
    color: #e9e9eb;
  }

  blockquote > p:first-of-type {
    max-width: 20rem;
    margin: auto;
  }

  blockquote > p:last-of-type {
    text-align: center;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 0.75rem;
    color: #969696;
  }

  #logo {
    width: 11.52rem;
    display: block;
  }
`

