import React from "react"
import "../assets/styles/global.css"

import { graphql, useStaticQuery } from "gatsby"
import HeaderWithQuote from "../components/Header/HeaderWithQuote"
import FullPageLayout from "../layouts/FullPageLayout"
import Section from "../components/Section/Section"
import AboutUs from "../components/Content/About-Us/AboutUs"
import Footer from "../components/Bar/footer"
import ContactComponent from "../components/Content/Contact/ContactComponent"
import DelimiterSVG, { SVGDelimiter } from "../components/Section/SVGDelimiter"
import Sponsors from "../components/Content/Testimonials/Sponsors"
import EbookComponent from "../components/Content/Ebooks/EbookComponent"
import StudyProcess from "../components/Content/StudyProcess/StudyProcess"
import { Founder } from "../components/Content/About-Us/Founder"
import Services from "../components/Content/Services"
import { ABOUT_US_TOOLTIPS } from "../const/MenuTooltips"
import { SEO_OPTIONS_ABOUT_US } from "../const/SEOOptions"


const AboutUsPage = ({location}) => {
  const data = useStaticQuery(graphql`
    {  
    headerBackground: file(
        relativePath: { eq: "about-us-header-background.jpg"}
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }      
    }
  `)

  const fullpageOptions = {
    navigationTooltips: ABOUT_US_TOOLTIPS
  }

  return (
    <FullPageLayout
      options={fullpageOptions}
      SEOOptions={SEO_OPTIONS_ABOUT_US}
      location={location}
    >
      <Section isFirstSection>
        <HeaderWithQuote
          quote={` “Our Mission is to Support Health Progress: We Ease the
                lives of Decision Makers”`}
          author={`— nadia petiot, founder of focus datascience`}
          img={data.headerBackground.childImageSharp.fluid}
          backgroundColor={`#1A213D`}
        />
      </Section>

      <Section
        Background={"#010921"}
      >
        <AboutUs />
      </Section>

      <Section
        Background={"#010921"}
      >
        <Founder />
      </Section>

      <Section
        Background={`#010921`}
      >
        <Services
        />
      </Section>

      <DelimiterSVG
        shape={SVGDelimiter.Shape.Rounded}
        fillColor={"#010921"}
        Background={`#141b48`}
      />

      <Section
        GradientBackground={`linear-gradient(180deg,rgba(20, 27, 72, 1) 0%,rgba(21, 24, 81,1) 50%)`}>
        <StudyProcess
          h2={`we believe in a stressless <br /> health progress`}
          h5={`Here Are Our Commitments`}
        />
      </Section>

      <DelimiterSVG
        shape={SVGDelimiter.Shape.Rounded}
        fillColor={`#151851`}
        Background={`#010921`}
      />

      <Section
        Background={`#010921`}
      >
        <EbookComponent isOnEbookPage/>
      </Section>

      <Section
        GradientBackground={`radial-gradient(circle at 50% 50%,rgba(4, 19, 72, 1) 0%,rgba(1, 9, 33, 1) 12rem)`}
        autoHeight
      >
        <Sponsors />
      </Section>

      <DelimiterSVG
        shape={SVGDelimiter.Shape.Line}
        fillColor={`#010921`}
        Background={`transparent`}
      />

      <ContactComponent
        DisplayContactForm={true}
      />

      <Footer location={location} />
    </FullPageLayout>
  )
}

export default AboutUsPage